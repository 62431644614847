import React, { Component } from 'react';
import {Link, Redirect} from "react-router-dom";
import Swal from 'sweetalert2'
export default class Header extends Component {
    constructor(props) {
        super(props);
        this.handleClickLogout = this.handleClickLogout.bind(this)
    }

    state = {
        toDashboard: false,
    };

    backToHome(){
        window.location.href = "/kyc"
    }

    handleClickLogout(){
        Swal.fire({
            title: 'Do you really wish to Logout?',
            confirmButtonText: 'Yes',
            showCancelButton: true,
            confirmButtonColor: '#FFC107',
          }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    position: 'middle',
                    icon: 'success',
                    title: 'Log Out Success!',
                    showConfirmButton: false,
                    timer: 1500
                  })
                localStorage.setItem('isLoggedIn', false);
                localStorage.removeItem('isLoggedIn');
                localStorage.removeItem('randid')
                localStorage.removeItem('currentUser')
                this.setState({ toDashboard: true });
            } 
          })
        //localStorage.removeItem('token');
    }

    render() {
        var currentAdmin = JSON.parse(localStorage.getItem('currentUser'));
        //console.log("currentAdminData", currentAdmin);
        
        if (this.state.toDashboard === true) {
            return <Redirect to='/' />
        }
        return (
            <nav className="navbar navbar-expand navbar-light bg-white static-top" >
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            <img
              className="sidebar-heading d-flex align-items-center"
              src="/img/ups_icon.png"
              alt="logo"
              width={60} height={60}
              onClick={this.backToHome} 
            /> 
            &emsp;

                <form className="d-none d-md-inline-block form-inline ml-auto mr-0 mr-md-3 my-2 my-md-0">
                    <div className="input-group">
                    </div>
                </form>

                <ul className="navbar-nav ml-auto ml-md-0">
                    <li className="nav-item dropdown no-arrow">
                        <Link to={'#'} 
                            className="nav-link dropdown-toggle" 
                            id="userDropdown" 
                            role="button"
                            data-toggle="dropdown" 
                            aria-haspopup="true" 
                            aria-expanded="false">                            
                                <b style={{ paddingRight: 15 }}>Welcome, {currentAdmin.b} !</b> 
                                <i className="fas fa-user-circle fa-2x"></i> 
                        </Link>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown">
                            <Link 
                                to={'#'} 
                                onClick={this.handleClickLogout} 
                                className="dropdown-item" 
                                data-toggle="modal" 
                                data-target="#logoutModal" >
                                    <b className="fas fa-sign-out-alt">&nbsp;LOGOUT</b>
                            </Link>                        
                        </div>
                        
                    </li>
                </ul>
            </nav>
        );
    }
}
