import React, {Component} from 'react';
import axios from 'axios';
import Swal from 'sweetalert2'
//import {Link, Redirect} from 'react-router-dom';

export default class Login extends Component {

    state = {
        username: '',
        password: '',
        redirect: false,
        authError: false,
        isLoading: false,
        location: {},
    };

    handleUsernameChange = event => {
        this.setState({username: event.target.value});
    };
    handlePwdChange = event => {
        this.setState({password: event.target.value});
    };

    handleSubmit = event => {
        event.preventDefault();
        this.setState({isLoading: true});
        const url =  'https://v2.unified.ph/admin/login';
        this.token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InJlbmVib3kiLCJyZWdjb2RlIjoidGVzdCIsImlhdCI6MTY1MzM3ODI2M30.nk3ltTC06VmmDuqYAwNpiP-URQGNsg_IwvoAnQZewbQ';
        const username = this.state.username;
        const password = this.state.password;
        let bodyFormData = new FormData();
        bodyFormData.set('username', username);
        bodyFormData.set('password', password);
        axios.post(url, bodyFormData, {
            headers: {
                'Authorization': `bearer ${this.token}`
            },
        })
            .then(result => {
                if (result.status === 200) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Logged In Successfully!',
                        showConfirmButton: false,
                        timer: 1500
                      })
                    // console.log(result.data.result[0])
                    setTimeout((function() {
                        window.location.href = "/kyc"
                      }), 1600);
                    localStorage.setItem('currentUser', JSON.stringify(result.data.result[0]));
                    //this.setState({redirect: true, isLoading: false});
                    localStorage.setItem('isLoggedIn', true);
                }
            })
            .catch(error => {
                //console.log("ERROR LOGIN",error);
                Swal.fire({
                    icon: 'error',
                    title: 'Invalid Credentials!',
                    text: 'Please try again.',
                    showConfirmButton: false,
                    timer: 1500
                  })
                this.setState({authError: true, isLoading: false});
            });
    };

    componentDidMount() {
        //
    }

    render() {
        const isLoading = this.state.isLoading;
        return (
            <div className="container"  >
                <div className="card card-login mx-auto mt-5" style={{ width: "30rem",background:"rgba(0,0,0,.75)" }}>
                <br></br>
                <div className="pb-3" style={{ fontSize: 21,padding:"5px 0", color: 'yellow'}}>
                    <center>         
                        <img
                        className="sidebar-heading d-flex align-items-center"
                        src="/img/ups_icon.png"
                        alt="logo"
                        width={90} height={90} 
                        /> 
                    </center>
                </div>
                <div className="pb-3" style={{ fontSize: 35,padding:"20px 0", color: 'white',lineHeight:"100%"}}><center><b>V2 LOGIN</b></center></div>
                    <div className="card-body" >
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-group mb-4">
                                <div className="form-label-group" 
                                     style={{ 
                                            paddingRight: 25,
                                            paddingLeft: 25
                                            }}>
                                    <input className={"form-control " + (this.state.authError ? 'is-invalid' : '')} 
                                           id="inputEmail" 
                                           placeholder="Username" 
                                           type="text" name="username" 
                                           onChange={this.handleUsernameChange} 
                                           autoFocus 
                                           required
                                    />
                                    <label htmlFor="inputEmail" style={{ 
                                            paddingRight: 0,
                                            paddingLeft: 35
                                            }}>Username</label>
                                    <div className="invalid-feedback">
                                    The username you have entered is incorrect / does not exist.
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-label-group" 
                                     style={{ 
                                            paddingRight: 25,
                                            paddingLeft: 25
                                            }}>
                                    <input type="password" className={"form-control " + (this.state.authError ? 'is-invalid' : '')} id="inputPassword" placeholder="******" name="password" onChange={this.handlePwdChange} required/>
                                    <label htmlFor="inputPassword"  style={{ 
                                            paddingRight: 0,
                                            paddingLeft: 35
                                            }}>Password</label>
                                    <div className="invalid-feedback">
                                    The password you have entered is incorrect.
                                    </div>
                                </div>
                            </div>
                       
                            <div className="form-group"  style={{ 
                                            paddingRight: 25,
                                            paddingLeft: 25
                                        }}>
                                 
                                <button className="btn btn-warning btn-block" 
                                        type="submit" 
                                        disabled={this.state.isLoading ? true : false}>
                                        <b>LOGIN</b> &nbsp;&nbsp;&nbsp;
                                        {isLoading ? (
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        ) : (
                                        <span></span>
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}


