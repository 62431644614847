import React, {Component} from 'react';
import {BrowserRouter, BrowserRouter as Switch} from 'react-router-dom';
//import {BrowserRouter, BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Login from "./pages/login";
import UserList from  './pages/users/userList'
import AccessLevels from  './pages/users/accessLevels'
import BillsPay from './pages/billspayment/billsPay'
import Kyc from './pages/kyc/kyc';
import KycApproved from './pages/kyc/kycApproved'
import KycRejected from './pages/kyc/kycRejected'
import GeneralReports from './pages/reports/generalReport';
//import Restricted404 from './middleware/Access404'
import PublicRoute from './hooks/PublicRoute'
import PrivateRoute from './hooks/PrivateRoute'
// import MissingRoute from './hooks/MissingRoute';
import 'antd/dist/antd.css';

class App extends Component {

    render() {
        return (
            <div className="App">
                <BrowserRouter>
                    <Switch>
                 
                        <PublicRoute path='/' component={Login}  exact />

                        {/* Users List */}
                        <PrivateRoute path='/userList' component={UserList} exact />
                        <PrivateRoute path='/accessLevels' component={AccessLevels} exact />

                        {/* Users List */}
                        <PrivateRoute path='/billsPay' component={BillsPay} exact />

                        {/* KYC Lists */}
                        <PrivateRoute path='/kyc' component={Kyc} exact />
                        <PrivateRoute path='/kycApproved' component={KycApproved} exact />
                        <PrivateRoute path='/kycRejected' component={KycRejected} exact />

                        {/* Reports */}
                        <PrivateRoute path='/genReport' component={GeneralReports} exact />

                        {/* <MissingRoute path='/*' element={<MissingRoute />} exact/> */}

                        {/* <PublicRoute path='/404' component={Restricted404} exact /> */}
                    </Switch>
                </BrowserRouter>
            </div>
        );
    }
}

export default App;
