import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Swal from 'sweetalert2';

export default class Sidebar extends Component {
    //Temporary function to show info modal to pages in development
    handleClickUnderDevelopment(){
        let timerInterval
        Swal.fire({
            imageUrl: 'https://cdn1.iconfinder.com/data/icons/ios-11-glyphs/30/maintenance-512.png',
            imageWidth: 115,
            imageHeight: 100,
            imageAlt: 'Under development',
            title: 'Sorry! This page is still under development.',
            allowOutsideClick: false,
            html: 'Redirecting you to KYC page in a few seconds.',
            timer: 1500,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading()
              const b = Swal.getHtmlContainer().querySelector('b')
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft()
              }, 100)
            },
            willClose: () => {
              clearInterval(timerInterval)
            }
          })
          setTimeout((function() {
            window.location.href = "/kyc"  //redirects to KYC page after 1.6s
          }), 1600);
    }

    render() {
        var currentAdmin = JSON.parse(localStorage.getItem('currentUser'));
        // console.log(currentAdmin.j)
        return (
            <div id="wrapper" >
                <ul className="sidebar navbar-nav"      
                    style={{
                    backgroundColor: '#34495E',
                    }}
                >
                {/* {(currentAdmin.j === "IT Department") 
                 ?
                    <li className="nav-item active">
                        <Link to={'#'} onClick={this.handleClickUnderDevelopment} className="nav-link">
                            <i className="fas fa-home" ></i>
                                <span style={{ fontSize: 12,fontWeight: 'bold' }}>&nbsp;DASHBOARD</span></Link>
                    </li>
                    
                : "" } */}
                <hr className="m-0 mb-0" />
                {(currentAdmin.j === "IT Department") //User access restriction
                ?
                          <li class="nav-item dropdown active">
                          {/* eslint-disable-next-line */}
                          <a class="nav-link accordion-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i className="fas fa-user" ></i>
                                  <span style={{ fontSize: 12,fontWeight: 'bold' }}>&nbsp;USER MANAGEMENT</span>
                          </a>
                          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                          <Link to={'/userList'} className="nav-link">
                              <i className="fas fa-folder" style={{ color: '#16537e', textAlign: "center", fontSize: 11 }}></i>
                                  <span style={{ color: '#16537e', fontSize: 12, fontWeight: 'bold' }}>&nbsp;USER LIST</span>
                          </Link>
                          <hr className="m-0 mb-0" />
                          <Link to={'/accessLevels'} className="nav-link">
                              <i className="fas fa-folder" style={{ color: '#16537e', textAlign: "center", fontSize: 11 }}></i>
                                  <span style={{ color: '#16537e', fontSize: 12, fontWeight: 'bold' }}>&nbsp;ACCESS LEVELS</span>
                          </Link>
                          <hr className="m-0 mb-0" />
                          <Link to={'#'} onClick={this.handleClickUnderDevelopment} className="nav-link">
                              <i className="fas fa-folder" style={{ color: '#16537e', textAlign: "center", fontSize: 11 }}></i>
                                  <span style={{ color: '#16537e', fontSize: 12, fontWeight: 'bold' }}>&nbsp;ACCESS LOGS</span>
                          </Link>
                      </div>
                      </li>
                : "" }
             
                <hr className="m-0 mb-0" />
                {(currentAdmin.j === "IT Department") 
                ?
                    <li className="nav-item active">
                        <Link to={'/billsPay'} className="nav-link">
                            <i className="fas fa-money-bill"></i>
                                <span style={{ fontSize: 12,fontWeight: 'bold' }}>&nbsp;BILLER MANAGEMENT</span></Link>
                    </li>
                : "" }
                <hr className="m-0 mb-0" />
                {/* {(currentAdmin.j === "IT Department") 
                ? */}
                    <li className="nav-item active">
                        <Link to={'/kyc'} className="nav-link">
                            <i className="fas fa-id-card"></i>
                                <span style={{ fontSize: 12,fontWeight: 'bold' }}>&nbsp;KYC VERIFICATION</span></Link>
                    </li>
                {/* : "" } */}
                    <hr className="m-0 mb-0" />
                {(currentAdmin.j === "IT Department")
                    ?
                    <li class="nav-item dropdown active">
                        {/* eslint-disable-next-line */}
                        <a class="nav-link accordion-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fas fa-chart-bar" ></i>
                                <span style={{ fontSize: 12,fontWeight: 'bold' }}>&nbsp;REPORTS</span>
                        </a>
                        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                        {/* <Link to={'#'} onClick={this.handleClickUnderDevelopment} className="nav-link">
                            <i className="fas fa-folder" style={{ color: '#16537e', textAlign: "center", fontSize: 12 }}></i>
                                <span style={{ color: '#16537e', fontSize: 12, fontWeight: 'bold' }}>&nbsp;GENERAL REPORTS</span>
                        </Link> */}
                        <Link to={'/genReport'} className="nav-link">
                            <i className="fas fa-folder" style={{ color: '#16537e', textAlign: "center", fontSize: 12 }}></i>
                                <span style={{ color: '#16537e', fontSize: 12, fontWeight: 'bold' }}>&nbsp;GENERAL REPORTS</span>
                        </Link>
                        <hr className="m-0 mb-0" />
                        <Link to={'#'} onClick={this.handleClickUnderDevelopment} className="nav-link">
                            <i className="fas fa-folder" style={{ color: '#16537e', textAlign: "center", fontSize: 12 }}></i>
                                <span style={{ color: '#16537e', fontSize: 12, fontWeight: 'bold' }}>&nbsp;LOADING REPORTS </span>
                        </Link>
                        {/* <hr className="m-0 mb-0" />
                        <Link to={'#'} onClick={this.handleClickUnderDevelopment} className="nav-link">
                            <i className="fas fa-folder" style={{ color: '#16537e', textAlign: "center", fontSize: 12 }}></i>
                                <span style={{ color: '#16537e', fontSize: 15, fontWeight: 'bold' }}>&nbsp;SUBMENU </span>
                        </Link> */}
                    </div>
                    </li>
                : "" }
                    <hr className="m-0 mb-0" />

                </ul>
            </div>
        );
    }
}
