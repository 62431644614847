import { Table, Space, PageHeader, Button, Input, Tag, DatePicker } from "antd";
import Highlighter from 'react-highlight-words';
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import React from 'react';
import Header from "../../elements/header";
import Sidebar from "../../elements/sidebar";
import 'antd/dist/antd.css';
import moment from 'moment';
import Swal from 'sweetalert2';
//import { VList } from 'virtual-table-ant-design'
// import {CSVLink} from "react-csv"

function UserList() {
  //var nf = new Intl.NumberFormat();
  const [dataSource, setDataSource] = useState([]);
  const [page, setPage] = useState([1]);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  // eslint-disable-next-line
  const [ searchTime, searchTimeText] = useState('') 
  const [searchedColumn, setSearchedColumn] = useState('');
  // eslint-disable-next-line
  const [ setsearchedTime, setSearchedTimeColumn] = useState('');
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const handleTimeRangeReset = (clearFilters) => {
    clearFilters();
      searchTimeText('');
      setSearchedTimeColumn('');
  };

  const handleTimeRangeSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
      searchTimeText(selectedKeys[0]);
      setSearchedTimeColumn(dataIndex);
      // console.log("searched time", selectedKeys[0])
      // console.log("searched time2", dataIndex)
      // console.log("searched time3", confirm)
  };

  const handleClickUnderDevelopment = (event) => {
    let timerInterval
    Swal.fire({
        imageUrl: 'https://cdn1.iconfinder.com/data/icons/ios-11-glyphs/30/maintenance-512.png',
        imageWidth: 115,
        imageHeight: 100,
        imageAlt: 'Under development',
        title: 'Sorry! This page is still under development.',
        allowOutsideClick: false,
        html: 'Redirecting you to KYC page in a few seconds.',
        timer: 1500,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading()
          const b = Swal.getHtmlContainer().querySelector('b')
          timerInterval = setInterval(() => {
            b.textContent = Swal.getTimerLeft()
          }, 100)
        },
        willClose: () => {
          clearInterval(timerInterval)
        }
      })
      setTimeout((function() {
        window.location.href = "/accessLevels"
      }), 1600);
  }

  const getColumnTimeProps = (dataIndex) => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
      <div style={{ padding: 8 }}>
        <DatePicker.RangePicker
          onChange={e => {
            setSelectedKeys(e.length ? [e] : [])
          }}
          placeholder={["Start Date", "End Date"]}
          value={selectedKeys[0]}
          format="YYYY-MM-DD HH:mm"
        />
        <Button
          type="primary"
          role="search"
          onClick={() => handleTimeRangeSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 90, marginLeft: 8, marginRight: 8 }}
          size="small"
        >
          Search
        </Button>
        <Button
          role="reset"
          style={{ width: 90 }}
          onClick={() => handleTimeRangeReset(clearFilters)}
          size="small"
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <i class="fa fa-filter" type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => record[dataIndex] ? moment(record[dataIndex]).isBetween(moment(value[0]), moment(value[1])) : "",
    render: text => text
  });

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<i class="fa fa-search" aria-hidden="true" />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <i class="fa fa-search" aria-hidden="true"
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  useEffect(() => {
    setLoading(true); //shows loading spinner indicating ongoing render of data
    axios
      .get('https://v2.unified.ph/v2-admin/user', {
        headers: {
          'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InJlbmVib3kiLCJyZWdjb2RlIjoidGVzdCIsImlhdCI6MTY1MzM3ODI2M30.nk3ltTC06VmmDuqYAwNpiP-URQGNsg_IwvoAnQZewbQ`
      }})
      .then((res) => {
        setDataSource(res?.data.data.rows);
        setLoading(false); //hides loading spinner after finishing render of data
        //console.log("USER_LIST", res?.data.data.rows)
      });
  }, []);
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      align: 'center',
      fixed: 'left',
      defaultSortOrder: 'descend',
      ...getColumnSearchProps('id'),
      sorter: (a, b) => a.id - b.id,
    },
    {
        title: "Regcode",
        dataIndex: "regcode",
        align: 'center',
 
        ...getColumnSearchProps('regcode'),
        //sorter: (a, b) => a.regcode - b.regcode,
      },
      {
        title: "User Name",
        dataIndex: "username",
        align: 'center',
        width: '8%',
  
        ...getColumnSearchProps('username'),
        //sorter: (a, b) => a.regcode - b.regcode,
      },
      {
        title: "First Name",
        dataIndex: "firstName",
        align: 'center',
  
        ...getColumnSearchProps('firstName'),
        //sorter: (a, b) => a.regcode - b.regcode,
      },
      {
        title: "Middle Initial",
        dataIndex: "middleName",
        align: 'center',
  
        ...getColumnSearchProps('middleName'),
        //sorter: (a, b) => a.regcode - b.regcode,
      },
      {
        title: "Last Name",
        dataIndex: "lastName",
        align: 'center',
   
        ...getColumnSearchProps('lastName'),
        //sorter: (a, b) => a.regcode - b.regcode,
      },
      {
        title: "Email Address",
        dataIndex: "emailAddress",
        align: 'center',
        width: '15%',

        ...getColumnSearchProps('emailAddress'),
        //sorter: (a, b) => a.regcode - b.regcode,
      },
      {
        title: "Mobile Number",
        dataIndex: "mobileNumber",
        align: 'center',
 
        ...getColumnSearchProps('mobileNumber'),
        //sorter: (a, b) => a.regcode - b.regcode,
      },
      {
        title: "User Level",
        dataIndex: "userlevel",
        align: 'center',
        width: '8%',
   
        filters: [
          {
            text: <Tag color="#000FFF">PINOY DEALER</Tag>,
            value: 1,
          },
          {
            text: <Tag color="#6C00FF">SUB DEALER</Tag>,
            value: 2,
          },
          {
            text: <Tag color="#64FF00">FREE ACCOUNT</Tag> ,
            value: 3,
          },
          {
            text: <Tag color="#0002A0">VISA RETAILER</Tag>  ,
            value: 4,
          },
          {
            text: <Tag color="#874FFF">RETAILER</Tag>  ,
            value: 5,
          },
          {
            text: <Tag color="#FFCD00">GLOBAL DEALER</Tag>   ,
            value: 6,
          },
          {
            text: <Tag color="#0063BF">HUB/RBC/CORPO</Tag>   ,
            value: 7,
          },
          {
            text: <Tag color="#FF7400">WITH LOANS</Tag>    ,
            value: 13,
          },
          
        ],
        filterMultiple: true,
        onFilter: (value, record) => record?.userlevel?.startsWith(value),
        //filterSearch: true,
        render: (userlevel) => { 
          return (
            <Space size="middle">
              {(userlevel === 1) 
                ? <Tag color="#000FFF">PINOY DEALER</Tag>
                : (userlevel === 2) 
                ? <Tag color="#6C00FF">SUB DEALER</Tag> 
                : (userlevel === 3) 
                ? <Tag color="#64FF00">FREE ACCOUNT</Tag> 
                : (userlevel === 4) 
                ? <Tag color="#0002A0">VISA RETAILER</Tag> 
                : (userlevel === 5) 
                ? <Tag color="#874FFF">RETAILER</Tag> 
                : (userlevel === 6) 
                ? <Tag color="#FFCD00">GLOBAL DEALER</Tag> 
                : (userlevel === 7) 
                ? <Tag color="#0063BF">HUB/RBC/CORPO</Tag> 
                : (userlevel === 13) 
                ? <Tag color="#FF7400">WITH LOANS</Tag> 
                : (userlevel === 14) 
                ? <Tag color="purple">TANGIBLE DEALER (PINOY)</Tag> 
                : (userlevel === 15) 
                ? <Tag color="geekblue">GLOBAL DEALER</Tag> 
                : (userlevel === 16) 
                ? <Tag color="#00B2FF">UNIFIED PAY CENTER</Tag>
                : <Tag color="#C70039">UNDEFINED USER LEVEL</Tag>
              }
            </Space>
          )
        }
      },
      {
        title: "Created Date",
        dataIndex: "createdate",
        align: 'center',
        render: (createdate) => { return (<p>{moment(createdate).format("YYYY-MM-DD")}</p>)}
      },
      {
        title: "Updated At",
        dataIndex: "updatedAt",
        align: 'center',
 
        // defaultSortOrder: 'descend',
        ...getColumnTimeProps('updatedAt'),
        sorter: (a, b) => moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
        render: (updatedAt) => { 
          return (
          <p>
          {(updatedAt === null) 
            ? ""
            : moment(updatedAt).format("YYYY-MM-DD HH:mm")
          }
          </p>)}
      },
      {
        title: <center>Actions</center>,
        key: 'action',
        fixed: 'right',
        width: '12%',
    
        render: (_, users) => (
          <Space size="middle">
                <div style={{    justifyContent: 'center', }}>
                <Button type="text" value={users.regcode} 
                        className="btn btn-link text-primary text-decoration-none p-1 mx-2 font-sm" 
                        style={{ color: '#FFB200' }} 
                        onClick={() => {
                          handleClickUnderDevelopment(users.regcode);
                        }}>
                        <i className="fas fa-edit"  style={{ color: '#FFB200' }} />&nbsp;<b  style={{ color: '#FFB200', fontSize: 12 }}>{"EDIT USER"}</b>
                </Button>
                {"|"}
                <Button type="text" value={users.regcode} 
                        className="btn btn-link text-primary text-decoration-none p-1 mx-2 font-sm" 
                        style={{ color: '#16537e' }} 
                        onClick={() => {
                          handleClickUnderDevelopment(users.regcode);
                        }}>
                        <i className="fas fa-ban"  style={{ color: '#FF3333' }} />&nbsp;<b  style={{ color: '#FF3333', fontSize: 12 }}>{"BLOCK"}</b>
                </Button>
                </div>      
          </Space>
        ),
      },
  ];

  // const fetchUserList = async () => {
  //   setLoading(true);
  //   await axios
  //     .get('https://v2.unified.ph/v2-admin/user', {
  //       headers: {'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InJlbmVib3kiLCJyZWdjb2RlIjoidGVzdCIsImlhdCI6MTY1MzM3ODI2M30.nk3ltTC06VmmDuqYAwNpiP-URQGNsg_IwvoAnQZewbQ`
  //     }})
  //     .then((res) => {
  //       setDataSource(res?.data.data.rows);
  //       setTotalPages(setDataSource.totalPages);
  //       setLoading(false);
  //       console.log("USER_LIST", res?.data.data.rows)
  //     });
  // };

  return (
       <div style={{
        backgroundColor: '#EEEEEE',
        }}>
          <Header/>
          <div id="wrapper">
              <Sidebar/>
              <div id="content-wrapper">
              <div className="container-fluid">
                  <div><br></br></div>
              {/* <h5 style={{
                  color: '#6C757D',  
                  paddingRight: 0,
                  paddingLeft: 25
                  }}>
                    KYC VERIFICATION LIST
              </h5> */}
              <div style={{
                   backgroundColor: '#FFFFFF',
                   }}>
                <PageHeader
                    style={{
                    border: '1px solid rgb(235, 237, 240)',
                    }}
                    // onBack={() => null}
                    title="User Management List"
                    subTitle="All of Unified V2 Registered Users"
                />
              </div>
         <br></br>
        <hr className="m-0 mb-3" />
            <div class="col text-right" style={{ padding:"0px 25px"}}>
            {/* <CSVLink
              filename={"GenReports_Table.csv"}
              data={dataSource}
              className="btn btn-warning btn-sm"
            >
            <i class="fas fa-file-export" style={{ fontSize: 12,  color: 'white'}} aria-hidden="true" />&nbsp;
            <b style={{ fontSize: 12,  color: 'white'}}>EXPORT CSV</b>
            </CSVLink> */}
            &nbsp;
            <button className="btn btn-secondary btn-sm"
                    onClick={() => {window.location.reload(useEffect);}}>
              <i class="fa fa-sync" aria-hidden="true" />&nbsp;
              <b style={{ fontSize: 12,  color: 'white'}}>REFRESH</b>
            </button> 
        </div>
        
          <div className="card-body">
          {/* {dataSource.length > 0 &&  //table remains hidden until data has been fully rendered */}
          <Table
            bordered
            loading={loading}
            columns={columns}
            dataSource={dataSource}
            // footer={() => '*Note : The table auto-refreshes every few minutes as to update for new KYC submissions.'}
            rowKey="regcode"
            pagination={{
            // showSizeChanger:false, //removes pagesize changer
              current:page,
              pageSize:pageSize,
            onChange: (page,pageSize) => {
              setPage(page);
              setPageSize(pageSize)
            },
            }}
            scroll={{
              x: 2000,
            }}
          />
           {/* } */}
          </div>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  }}>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}
export default UserList;