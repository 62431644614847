import { Table, Space, PageHeader, Button, Input, Tag, DatePicker } from "antd";
import Highlighter from 'react-highlight-words';
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import React from 'react';
import Header from "../../elements/header";
import Sidebar from "../../elements/sidebar";
import 'antd/dist/antd.css';
import moment from 'moment';
//import Swal from 'sweetalert2';
import {CSVLink} from "react-csv"

function GeneralReports() {
  var nf = new Intl.NumberFormat();
  
  const [dataSource, setDataSource] = useState([]);
  const [page, setPage] = useState([1]);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  // eslint-disable-next-line
  const [ searchTime, searchTimeText] = useState('') 
  const [searchedColumn, setSearchedColumn] = useState('');
  // eslint-disable-next-line
  const [ setsearchedTime, setSearchedTimeColumn] = useState('');
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const handleTimeRangeReset = (clearFilters) => {
    clearFilters();
      searchTimeText('');
      setSearchedTimeColumn('');
  };

  const handleTimeRangeSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
      searchTimeText(selectedKeys[0]);
      setSearchedTimeColumn(dataIndex);
  };

  const getColumnTimeProps = (dataIndex) => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
      <div style={{ padding: 8 }}>
        <DatePicker.RangePicker
          onChange={e => {
            setSelectedKeys(e.length ? [e] : [])
          }}
          placeholder={["Start Date", "End Date"]}
          value={selectedKeys[0]}
          format="YYYY-MM-DD"
        />
        <Button
          type="primary"
          role="search"
          onClick={() => handleTimeRangeSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 90, marginLeft: 8, marginRight: 8 }}
          size="small"
        >
          Search
        </Button>
        <Button
          role="reset"
          style={{ width: 90 }}
          onClick={() => handleTimeRangeReset(clearFilters)}
          size="small"
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <i class="fa fa-filter" type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => record[dataIndex] ? moment(record[dataIndex]).isBetween(moment(value[0]), moment(value[1])) : "",
    render: text => text
  });

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<i class="fa fa-search" aria-hidden="true" />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <i class="fa fa-search" aria-hidden="true"
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  useEffect(() => {  
    setLoading(true);
    axios
      .post('http://35.201.239.8:4000/admin/reports', null, {
      //.post('https://v2.unified.ph/admin/reports', null, {
        headers: {
            'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InJlbmVib3kiLCJyZWdjb2RlIjoidGVzdCIsImlhdCI6MTY1MzM3ODI2M30.nk3ltTC06VmmDuqYAwNpiP-URQGNsg_IwvoAnQZewbQ`
        },})
      .then((res) => {
        // let genRepData = res.data.data;
        // if(data.maxCount >= 10){
        //   genRepData
        // }
        setDataSource(res?.data.data);
        setLoading(false);
        //console.log("GEN_REP_TOTALPAGES", res?.data.maxCount);
      });
    // eslint-disable-next-line
  }, []);
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      fixed: 'left',
      width: '3%',
      align: 'center',
      ...getColumnSearchProps('id'),
    },
    {
        title: "Regcode",
        dataIndex: "regcode",
        align: 'center',
        fixed: 'left',
        width: '3%',
        ...getColumnSearchProps('regcode'),
        //sorter: (a, b) => a.regcode - b.regcode,
      },
    {
        title: "Tracking Number",
        dataIndex: "trackingNumber",
        align: 'center',
        width: '5%',
        ...getColumnSearchProps('trackingNumber'),
      },
      {
        title: "Transaction Type",
        dataIndex: "transType",
        align: 'center',
        width: '4%',
        filterMode: 'tree',
        filters: [
            {
              text: <Tag color="green">REMITTANCE SERVICES</Tag>,
              // value: '22',
              children: [
                {
                  text: 'CREDIT TO BANK',
                  value: 'r1',
                },
                {
                  text: 'SMARTMONEY',
                  value: 'r2',
                },
                {
                  text: 'iREMIT',
                  value: 'r3',
                },
                {
                  text: 'WESTERN UNION',
                  value: 'r4',
                },
                {
                  text: 'MONEYGRAM',
                  value: 'r5',
                },
                {
                  text: 'CEBUANA LHUILLIER',
                  value: 'r6',
                },
                {
                  text: 'ECASH TO ECASH',
                  value: 'r7',
                },
                {
                  text: 'ECASH TO CASHCARD',
                  value: 'r8',
                },
                {
                  text: 'ECASH PADALA',
                  value: 'r9',
                },
              ],
            },
            // {
            //   text: <Tag color="geekblue">UNDEFINED TYPE</Tag>,
            //   value: 'PENDING',
            // },
            {
              text: <Tag color="geekblue">BILLS PAYMENT SERVICES</Tag>,
              // value: 'b0',
              children: [
                {
                  text: 'BAYAD CENTER',
                  value: 'b1',
                },
                {
                  text: 'SECURITY BANK',
                  value: 'b2',
                },
                {
                  text: 'ECASHPAY',
                  value: 'b3',
                },
              ],
            },
            {
              text: <Tag color="cyan">INSURANCE SERVICES</Tag>,
              // value: 'b0',
              children: [
                {
                  text: 'MAXICARE',
                  value: 'i1',
                },
                {
                  text: 'PERSONAL ACCIDENT - MALAYAN',
                  value: 'i2',
                },
                {
                  text: 'PERSONAL ACCIDENT - FPG',
                  value: 'i3',
                },
                {
                  text: 'TRAVEL INSURANCE - MALAYAN',
                  value: 'i4',
                },
                {
                  text: 'CTPL - FPG',
                  value: 'i5',
                },
              ],
            },
            {
              text: <Tag color="purple">FUNDING SERVICES</Tag>,
              // value: 'b0',
              children: [
                {
                  text: 'TOP UP',
                  value: 'f1',
                },
                {
                  text: 'CREDIT CARD TOP-UP',
                  value: 'f2',
                },
                {
                  text: 'ECASH BILLS PAY TOP-UP',
                  value: 'f3',
                },
              ],
            },
            {
              text: <Tag color="blue">ECASH REVERSE</Tag>,
              value: '00',
            },
            {
              text: <Tag color="blue">ECASH ROLLBACK</Tag>,
              value: '09',
            },
            {
              text: <Tag color="blue">ECASH TO CASHCARD</Tag>,
              value: '08',
            },
            {
              text: <Tag color="blue">CREDIT CARD</Tag>,
              value: '07',
            },
            {
              text: <Tag color="blue">DRAGONPAY</Tag>,
              value: '06',
            },
            {
              text: <Tag color="volcano">OTHERS</Tag>,
              value: '05',
            },
          ],
        filterMultiple: true,
        // onFilter: (value, record) => record.transType?.startsWith(value),
        onFilter: (value, record) => record.transType,
        filterSearch: true,
        // ...getColumnSearchProps('transType'),
        render: (transType) => { 
          return (
            <Space size="middle">
              {(transType === 22) 
                ? <Tag color="green">TRANSACTION TYPE 22</Tag>
                : <Tag color="red">UNDEFINED TYPE</Tag>
              }
            </Space>
          )
        }
      },  
      {
        title: "Destination",
        dataIndex: "destination",
        align: 'center',
        width: '3%',
        ...getColumnSearchProps('destination'),
      },  
      {
        title: "Amount",
        dataIndex: "amount",
        align: 'center',
        width: '3%',
        render: (amount) => { return (<p>₱ {amount.toLocaleString()}</p>)}
      },  
      {
        title: "Charges/Fee",
        dataIndex: "charge",
        align: 'center',
        width: '3%',
        render: (charge) => { return (<p>₱ {charge.toLocaleString()}</p>)}
      },  
  
    {
      title: "Created At",
      dataIndex: "createdAt",
      align: 'center',
      width: '4%',
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      render: (createdAt) => { return (<p>{moment(createdAt).format("YYYY-MM-DD HH:mm")}</p>)}
    },
    {
      title: "Company Income",
      dataIndex: "companyIncome",
      align: 'center',
      width: '4%',
      render: (companyIncome) => { return (<p>₱ {companyIncome.toLocaleString()}</p>)}
    },
    {
      title: "Balance Before",
      dataIndex: "balanceBefore",
      align: 'center',
      width: '3%',
      render: (balanceBefore) => { return (<p>₱ {nf.format(balanceBefore)}</p>)}
    },
    {
      title: "Balance After",
      dataIndex: "balanceAfter",
      align: 'center',
      width: '3%',
      render: (balanceAfter) => { return (<p>₱ {nf.format(balanceAfter)}</p>)}
    },
    {
      title: "Date",
      dataIndex: "date",
      align: 'center',
      width: '3%',
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      ...getColumnTimeProps('date'),
      render: (date) => { return (<p>{moment(date).format("YYYY-MM-DD")}</p>)}
    },
    {
      title: "Time",
      dataIndex: "time",
      align: 'center',
      width: '3%',
      //render: (time) => { return (<p>{moment(time).format('LT')}</p>)}
    },
    {
      title: "IP Address",
      dataIndex: "ipAddress",
      align: 'center',
      width: '3%',
    },
  ];

  const fetchGenReports = (page) => {

  };

  return (
       <div style={{
        backgroundColor: '#EEEEEE',
        }}>
          <Header/>
          <div id="wrapper">
              <Sidebar/>
              <div id="content-wrapper">
              <div className="container-fluid">
                  <div><br></br></div>
              {/* <h5 style={{
                  color: '#6C757D',  
                  paddingRight: 0,
                  paddingLeft: 25
                  }}>
                    KYC VERIFICATION LIST
              </h5> */}
              <div style={{
                   backgroundColor: '#FFFFFF',
                   }}>
                <PageHeader
                    style={{
                    border: '1px solid rgb(235, 237, 240)',
                    }}
                    // onBack={() => null}
                    title="General Reports "
                    subTitle=" Currently Ongoing Development"
                />
              </div>
         <br></br>
        <hr className="m-0 mb-3" />
            <div class="col text-right" style={{ padding:"0px 25px"}}>
            <CSVLink
              filename={"GenReports_Table.csv"}
              data={dataSource}
              className="btn btn-warning btn-sm"
            >
            <i class="fas fa-file-export" style={{ fontSize: 12,  color: 'white'}} aria-hidden="true" />&nbsp;
            <b style={{ fontSize: 12,  color: 'white'}}>EXPORT CSV</b>
            </CSVLink>
            &nbsp;
            <button className="btn btn-secondary btn-sm"
                    onClick={() => {window.location.reload(fetchGenReports(1));}}>
              <i class="fa fa-sync" aria-hidden="true" />&nbsp;
              <b style={{ fontSize: 12,  color: 'white'}}>REFRESH</b>
            </button> 
        </div>
        
          <div className="card-body">
          <Table
            bordered
            loading={loading}
            columns={columns}
            dataSource={dataSource}
            // footer={() => '*Note : The table auto-refreshes every few minutes as to update for new KYC submissions.'}
            rowKey="regcode"
            pagination={{
              // showSizeChanger:false, //removes pagesize changer
                current:page,
                pageSize:pageSize,
              onChange: (page,pageSize) => {
                setPage(page);
                setPageSize(pageSize)
              },
              }}
        scroll={{
          x: 3000
        }}
    >
          </Table>
          </div>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  }}>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}
export default GeneralReports;