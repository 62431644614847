import { Table, Space, PageHeader, Button, Input } from "antd";
import Highlighter from 'react-highlight-words';
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import React from 'react';
import Header from "../../elements/header";
import Sidebar from "../../elements/sidebar";
import 'antd/dist/antd.css';
import Swal from 'sweetalert2';
// import {CSVLink} from "react-csv"

function UserList() {
  //var nf = new Intl.NumberFormat();
  
  const [dataSource, setDataSource] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  // eslint-disable-next-line
  const [ searchTime, searchTimeText] = useState('') 
  const [searchedColumn, setSearchedColumn] = useState('');
  // eslint-disable-next-line
  const [ setsearchedTime, setSearchedTimeColumn] = useState('');
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const handleClickUnderDevelopment = (event) => {
    let timerInterval
    Swal.fire({
        imageUrl: 'https://cdn1.iconfinder.com/data/icons/ios-11-glyphs/30/maintenance-512.png',
        imageWidth: 115,
        imageHeight: 100,
        imageAlt: 'Under development',
        title: 'Sorry! This page is still under development.',
        allowOutsideClick: false,
        html: 'Redirecting you to KYC page in a few seconds.',
        timer: 1500,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading()
          const b = Swal.getHtmlContainer().querySelector('b')
          timerInterval = setInterval(() => {
            b.textContent = Swal.getTimerLeft()
          }, 100)
        },
        willClose: () => {
          clearInterval(timerInterval)
        }
      })
      setTimeout((function() {
        window.location.href = "/kyc"
      }), 1600);
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<i class="fa fa-search" aria-hidden="true" />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <i class="fa fa-search" aria-hidden="true"
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  useEffect(() => {
    fetchBillerList(1);
    // eslint-disable-next-line
  }, []);
  const columns = [
    {
      title: "Biller Code",
      dataIndex: "billerCode",
      align: 'center',
      fixed: 'left',
      width: '2%',
   
      ...getColumnSearchProps('billerCode'),
      sorter: (a, b) => a.billerCode - b.billerCode,
    },
      {
        title: "Biller Name",
        dataIndex: "billerName",
        align: 'center',
        width: '3%',
        ...getColumnSearchProps('billerName'),
        //sorter: (a, b) => a.regcode - b.regcode,
      },
      {
        title: "Biller Description",
        dataIndex: "billerDesc",
        align: 'center',
        width: '6%',
      },
      {
        title: "Biller Category",
        dataIndex: "billerCategory",
        align: 'center',
        width: '3%',
        ...getColumnSearchProps('billerCategory'),
        //sorter: (a, b) => a.regcode - b.regcode,
      },
      {
        title: "Biller Group",
        dataIndex: "billerGroup",
        align: 'center',
        width: '3%',
        ...getColumnSearchProps('billerGroup'),
        //sorter: (a, b) => a.regcode - b.regcode,
      },
      {
        title: "Biller Provider ID",
        dataIndex: "billerProviderId",
        align: 'center',
        width: '3%',
        ...getColumnSearchProps('billerProviderId'),
        //sorter: (a, b) => a.regcode - b.regcode,
      },
      {
        title: "Biller Provider",
        dataIndex: "billerProvider",
        align: 'center',
        width: '3%',
        ...getColumnSearchProps('billerProvider'),
        //sorter: (a, b) => a.regcode - b.regcode,
      },
      {
        title: "Transaction Fee",
        dataIndex: "transFee",
        align: 'center',
        width: '3%',
        // ...getColumnSearchProps('billerGroup'),
        //sorter: (a, b) => a.regcode - b.regcode,
      },
      {
        title: <center>Actions</center>,
        key: 'action',
        fixed: 'right',
        width: '2%',
        render: (_, users) => (
          <Space size="middle">
                <div style={{    justifyContent: 'center', }}>
                <Button type="text" value={users.regcode} 
                        className="btn btn-link text-primary text-decoration-none p-1 mx-2 font-sm" 
                        style={{ color: '#FFB200', justifyContent: 'center', }} 
                        onClick={() => {
                            handleClickUnderDevelopment(users.regcode);
                          }}>
                        <i className="fas fa-edit"  style={{ color: '#FFB200' }} />&nbsp;<b  style={{ color: '#FFB200', fontSize: 12 }}>{"EDIT BILLER"}</b>
                </Button>
                </div>      
          </Space>
        ),
      },
  ];

  const fetchBillerList = (page) => {
      setLoading(true);
      axios
        .post('https://v2.unified.ph/v2-admin-billspayment/billers', null, {
          headers: {
              'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InJlbmVib3kiLCJyZWdjb2RlIjoidGVzdCIsImlhdCI6MTY1MzM3ODI2M30.nk3ltTC06VmmDuqYAwNpiP-URQGNsg_IwvoAnQZewbQ`
          },})
        .then((res) => {
          setDataSource(res?.data.data);
          setTotalPages(res?.data.data.totalPages);
          setLoading(false);
          //console.log("BILLER", res?.data.data)
        });
  };

  return (
       <div style={{
        backgroundColor: '#EEEEEE',
        }}>
          <Header/>
          <div id="wrapper">
              <Sidebar/>
              <div id="content-wrapper">
              <div className="container-fluid">
                  <div><br></br></div>
              <div style={{
                   backgroundColor: '#FFFFFF',
                   }}>
                <PageHeader
                    style={{
                    border: '1px solid rgb(235, 237, 240)',
                    }}
                    // onBack={() => null}
                    title="List of Billers"
                    subTitle=" Currently Ongoing Development"
                />
              </div>
         <br></br>
        <hr className="m-0 mb-3" />
            <div class="col text-right" style={{ padding:"0px 25px"}}>
            <button className="btn btn-warning btn-sm"
                    onClick={() => {window.location.reload(fetchBillerList(1));}}>
              <i style={{ color: 'white'}} class="fa fa-plus" aria-hidden="true" />&nbsp;
              <b style={{ fontSize: 12,  color: 'white'}}>ADD BILLER</b>
            </button> 
            {/* <CSVLink
              filename={"GenReports_Table.csv"}
              data={dataSource}
              className="btn btn-warning btn-sm"
            >
            <i class="fas fa-file-export" style={{ fontSize: 12,  color: 'white'}} aria-hidden="true" />&nbsp;
            <b style={{ fontSize: 12,  color: 'white'}}>EXPORT CSV</b>
            </CSVLink> */}
            &nbsp;
            <button className="btn btn-secondary btn-sm"
                    onClick={() => {window.location.reload(fetchBillerList(1));}}>
              <i class="fa fa-sync" aria-hidden="true" />&nbsp;
              <b style={{ fontSize: 12,  color: 'white'}}>REFRESH</b>
            </button> 
        </div>
        
          <div className="card-body">
          <Table
            bordered
            loading={loading}
            columns={columns}
            dataSource={dataSource}
            // footer={() => '*Note : The table auto-refreshes every few minutes as to update for new KYC submissions.'}
            rowKey="regcode"
        pagination={{
          // showSizeChanger:false, //removes pagesize changer
          // pageSize: 10,
          total: totalPages,
          onChange: (page) => {
            fetchBillerList(page);
          },
        }}
        scroll={{
          x: 2200,
        }}
    >
          </Table>
          </div>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  }}>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}
export default UserList;