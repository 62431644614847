import {
  Table,
  Space,
  PageHeader,
  Button,
  Modal,
  Image,
  Input,
  Tag,
  Radio,
  DatePicker,
  Alert,
  Form,
} from "antd";
import { Link } from "react-router-dom";
import Highlighter from "react-highlight-words";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import React from "react";
import Header from "../../elements/header";
import Sidebar from "../../elements/sidebar";
import "antd/dist/antd.css";
import moment from "moment";
import Swal from "sweetalert2";
import { CSVLink } from "react-csv";
import _ from "lodash";

function KYCTable() {
  const [state, setState] = useState({});
  const [dataSource, setDataSource] = useState([]);
  const [page, setPage] = useState([1]);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  // eslint-disable-next-line
  const [timeText, searchTimeText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  // eslint-disable-next-line
  const [searchedTimeColumn, setSearchedTimeColumn] = useState("");
  const [form] = Form.useForm();
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const handleTimeRangeReset = (clearFilters) => {
    clearFilters();
    searchTimeText("");
    setSearchedTimeColumn("");
  };

  const handleTimeRangeSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    searchTimeText(selectedKeys[0]);
    setSearchedTimeColumn(dataIndex);
    // console.log("searched time", selectedKeys[0])
    // console.log("searched time2", dataIndex)
    // console.log("searched time3", confirm)
  };

  const getColumnTimeProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <DatePicker.RangePicker
          onChange={(e) => {
            setSelectedKeys(e.length ? [e] : []);
          }}
          placeholder={["Start Date", "End Date"]}
          value={selectedKeys[0]}
          format="YYYY-MM-DD HH:mm"
          showTime
        />
        <Button
          type="primary"
          role="search"
          onClick={() =>
            handleTimeRangeSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 90, marginLeft: 8, marginRight: 8 }}
          size="small"
        >
          Search
        </Button>
        <Button
          role="reset"
          style={{ width: 90 }}
          onClick={() => handleTimeRangeReset(clearFilters)}
          size="small"
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <i
        class="fa fa-filter"
        type="search"
        style={{ color: filtered ? "#1890ff" : undefined }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? moment(record[dataIndex]).isBetween(
            moment(value[0]),
            moment(value[1])
          )
        : "",
    render: (text) => text,
  });

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<i class="fa fa-search" aria-hidden="true" />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <i
        class="fa fa-search"
        aria-hidden="true"
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  useEffect(() => {
    fetchKYCRecords(1);
    // eslint-disable-next-line
  }, []);

  const columnsPending = [
    {
      title: "ID",
      dataIndex: "id",
      //   defaultSortOrder: 'descend',
      fixed: "left",
      width: "5%",
      sorter: (a, b) => a.id - b.id,
    },
    // Table.EXPAND_COLUMN, // changes dropdown toggle location
    {
      title: "Regcode",
      dataIndex: "regcode",
      align: "center",
      ...getColumnSearchProps("regcode"),
    },
    {
      title: "First Name",
      dataIndex: "fname",
      align: "center",
      ...getColumnSearchProps("fname"),
      // render: (fname) => { return (fname.replaceAll('&#x2F;', '/').replaceAll('Ã±','ñ'))}
      render: (fname) => {
        return fname
          .replaceAll("&#x2F;", "/")
          .replaceAll("Ã±", "ñ")
          .replaceAll("Ã", "Ñ");
      },
    },
    {
      title: "Last Name",
      dataIndex: "lname",
      align: "center",
      ...getColumnSearchProps("lname"),
      // render: (lname) => { return (lname.replaceAll('&#x2F;', '/').replaceAll('Ã±','ñ'))}
      render: (lname) => {
        return lname
          .replaceAll("&#x2F;", "/")
          .replaceAll("Ã±", "ñ")
          .replaceAll("Ã", "Ñ");
      },
    },
    {
      title: "Submitted At",
      dataIndex: "createdAt",
      align: "center",
      ...getColumnTimeProps("createdAt"),
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      render: (createdAt) => {
        return <p>{moment(createdAt).format("YYYY-MM-DD HH:mm")}</p>;
      },
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      align: "center",
      defaultSortOrder: "descend",
      sorter: (a, b) => moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
      render: (updatedAt) => {
        return <p>{moment(updatedAt).format("YYYY-MM-DD HH:mm")}</p>;
      },
    },
    {
      title: "Action By",
      dataIndex: "approvedBy",
      align: "center",
    },
    {
      title: "1st Approval",
      dataIndex: "approver_1",
      align: "center",
      width: "10%",
    },

    {
      title: "1st Approval Date",
      dataIndex: "approved_at1",
      align: "center",
      width: "10%",
      sorter: (a, b) =>
        moment(a.approved_at1).unix() - moment(b.approved_at1).unix(),
      render: (approved_at1) => {
        return (
          <p>
            {approved_at1
              ? moment(approved_at1).format("YYYY-MM-DD HH:mm")
              : ""}
          </p>
        );
      },
    },

    {
      title: "2nd Approver",
      dataIndex: "approver_2",
      align: "center",
      width: "10%",
    },

    {
      title: "2nd Approval Date",
      dataIndex: "approved_at2",
      align: "center",
      width: "10%",
      sorter: (a, b) =>
        moment(a.approved_at2).unix() - moment(b.approved_at2).unix(),
      render: (approved_at2) => {
        return (
          <p>
            {approved_at2
              ? moment(approved_at2).format("YYYY-MM-DD HH:mm")
              : ""}
          </p>
        );
      },
    },

    
    {
      title: "Approval Remarks",
      dataIndex: "approval_remarks",
      align: "center",
      width: "auto",
    },

    {
      title: "Status",
      dataIndex: "status",
      fixed: "right",
      align: "center",
      width: "10%",

      // filters: [
      //     {
      //       text: <Tag color="green">APPROVED</Tag>,
      //       value: 'APPROVED',
      //     },
      //     {
      //       text: <Tag color="geekblue">PENDING</Tag>,
      //       value: 'PENDING',
      //     },
      //     {
      //       text: <Tag color="red">REJECTED</Tag>,
      //       value: 'REJECTED',
      //     },
      //   ],
      // // filterMultiple: false,
      // defaultFilteredValue : ['PENDING'],
      // onFilter: (value, record) => record.status.startsWith(value),
      // filterSearch: true,
      render: (status) => {
        return (
          <Space size="middle">
            {status === "REJECTED" ? (
              <Tag color="red">REJECTED</Tag>
            ) : status === "APPROVED" ? (
              <Tag color="green">APPROVED</Tag>
            ) : (
              <Tag color="geekblue">{status}</Tag>
            )}
          </Space>
        );
      },
    },

    {
      title: <center>Actions</center>,
      key: "action",
      fixed: "right",
      width: "15%",
      render: (_, users) => (
        <Space size="middle">
          {users.status === "PENDING" ? (
            <div>
              <Button
                title={
                  users.approval_status ? "Pending for 2nd Approval" : "Approve"
                }
                disabled={users.approval_status && users.approval_status !== "PENDING"}
                type="text"
                value={users.regcode}
                className="btn btn-link text-primary text-decoration-none p-1 mx-2 font-sm"
                style={{ color: "#16537e" }}
                onClick={() => {
                  onApproveKYC(users.regcode);
                }}
              >
                <i className="fas fa-check mr-1" style={{ color: "#23C100" }} />
                <b style={{ color: "#23C100", fontSize: 12 }}>{"APPROVE"}</b>
              </Button>
              {"|"}
              <Button
                title={
                  users.approval_status ? "Pending for 2nd Approval" : "Reject"
                }
                type="text"
                value={users.regcode}
                className="btn btn-link text-primary text-decoration-none p-1 mx-2 font-sm"
                style={{ color: "#16537e" }}
                onClick={() => {
                  onRejectKYC(users.regcode);
                }}
                disabled={users.approval_status && users.approval_status !== "PENDING"}
              >
                <i className="fas fa-times mr-1" style={{ color: "#FF3333" }} />
                <b style={{ color: "#FF3333", fontSize: 12 }}>{"REJECT"}</b>
              </Button>
            </div>
          ) : (
            ""
          )}
        </Space>
      ),
    },
  ];

  const fetchKYCRecords = (page) => {
    setLoading(true);
    axios
      .get('https://v2.unified.ph/admin/kyc/PENDING' , {

  //    .get('http://localhost:8086/admin/kyc/PENDING' , {
            headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InJlbmVib3kiLCJyZWdjb2RlIjoidGVzdCIsImlhdCI6MTY1MzM3ODI2M30.nk3ltTC06VmmDuqYAwNpiP-URQGNsg_IwvoAnQZewbQ`,
        },
      })
      .then((res) => {
        setDataSource(_.filter(res.data, ["status", "PENDING"]));
        setLoading(false);
        //console.log("PENDINGKYC", _.filter(res.data, ['status', "PENDING"])) //array log of all the kyc submissions
      });
    setInterval(function () {
      // function to reload table for new data
      window.location.reload(fetchKYCRecords(1));
    }, 300000); //set interval to 300000milliseconds or 5minutes
  };

  const onApproveKYC = (event) => {
    Modal.confirm({
      title: "You will APPROVE the KYC of " + event,
      content: "Do you want to proceed?",
      okText: "Yes",
      okType: "success",
      onOk: () => {
        var adminData = JSON.parse(localStorage.getItem("currentUser"));
        // console.log("currentAdminData", adminData.b);
        // eslint-disable-next-line
        axios.post('https://v2.unified.ph/admin/kyc-approve' + '/' + event + '/' + adminData.b, null ,{   
              headers: {
                Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InJlbmVib3kiLCJyZWdjb2RlIjoidGVzdCIsImlhdCI6MTY1MzM3ODI2M30.nk3ltTC06VmmDuqYAwNpiP-URQGNsg_IwvoAnQZewbQ`,
              },
            }
          )
          .then((response) => {
            console.log(response);
            const data = response.data;
            let timerInterval;
            Swal.fire({
              position: "middle",
              icon: data.status === 1 ? "success" : "error",
              title: data.message,
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                const b = Swal.getHtmlContainer().querySelector("b");
                timerInterval = setInterval(() => {
                  b.textContent = Swal.getTimerLeft();
                }, 3000);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            });
            setTimeout(function () {
              window.location.reload(fetchKYCRecords(1));
            }, 3000);
            setState({ isLoading: true });
          })
          .catch((error) => {
            console.log(error.toString());
            let timerInterval;
            Swal.fire({
              // width: 600,
              position: "middle",
              icon: "error",
              title: error.toString(),
              // icon: 'error',
              // title: 'User KYC must be on PENDING status!',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                const b = Swal.getHtmlContainer().querySelector("b");
                timerInterval = setInterval(() => {
                  b.textContent = Swal.getTimerLeft();
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            });

            setTimeout(function () {
              window.location.reload(fetchKYCRecords(1));
            }, 5000);
          });
      },
    });
  };

  const onRejectKYC = (event) => {
    Modal.confirm({
      title: "You will REJECT the KYC of " + event,
      content: (
        <>
          <Form form={form} layout="vertical">
            <Form.Item name="remarks" label="Remarks">
              <Input type="textarea" required />
            </Form.Item>
          </Form>
        </>
      ),
      okText: "",
      okType: "error",
      onOk: () => {
        form
          .validateFields()
          .then((values) => {
            // console.log(values);
            if (values.remarks) {
              var adminData = JSON.parse(localStorage.getItem("currentUser"));
           //   console.log("currentAdminData", adminData.b);
              // eslint-disable-next-line
              adminData.rejectRemarks = values.remarks;
              axios
                .post(
                  "https://v2.unified.ph/admin/kyc-reject" +
                    "/" +
                    event +
                    "/" +
                    adminData.b,
                 {rejectRemarks: values.remarks},
                  {
                    headers: {
                      Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InJlbmVib3kiLCJyZWdjb2RlIjoidGVzdCIsImlhdCI6MTY1MzM3ODI2M30.nk3ltTC06VmmDuqYAwNpiP-URQGNsg_IwvoAnQZewbQ`,
                    },
                  }
                )
                .then((response) => {
                  console.log(response);
                  const data = response.data;

                  let timerInterval;
                  Swal.fire({
                    position: "middle",
                    icon: data.status === 1 ?  "success" : "error",
                    title: data.message,
                    showConfirmButton: false,
                    timer: 5000,
                    timerProgressBar: true,
                    didOpen: () => {
                      Swal.showLoading();
                      const b = Swal.getHtmlContainer().querySelector("b");
                      timerInterval = setInterval(() => {
                        b.textContent = Swal.getTimerLeft();
                      }, 100);
                    },
                    willClose: () => {
                      clearInterval(timerInterval);
                    },
                  });
                  setTimeout(function () {
                    window.location.reload(fetchKYCRecords(1));
                  }, 5000);
                  setState({ isLoading: true });
                })
                .catch((error) => {
                  console.log(error.toString());
                  let timerInterval;
                  Swal.fire({
                    //width: 600,
                    position: "middle",
                    // icon: 'error',
                    // title: 'User KYC must be on PENDING status!',
                    icon: "error",
                    title: error.toString(),
                    showConfirmButton: false,
                    timer: 5000,
                    timerProgressBar: true,
                    didOpen: () => {
                      Swal.showLoading();
                      const b = Swal.getHtmlContainer().querySelector("b");
                      timerInterval = setInterval(() => {
                        b.textContent = Swal.getTimerLeft();
                      }, 100);
                    },
                    willClose: () => {
                      clearInterval(timerInterval);
                    },
                  });

                  // setTimeout(function () {
                  //   window.location.reload(fetchKYCRecords(1));
                  // }, 1100);
                });
            }

            else {
              alert('Please Input remarks');
            }

            form.resetFields();
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      },
    });
  };

  return (
    <div
      style={{
        backgroundColor: "#EEEEEE",
      }}
    >
      <Header />
      <div id="wrapper">
        <Sidebar />

        <div id="content-wrapper">
          <div className="container-fluid">
            <div>
              <br></br>
            </div>
            {/* <h5 style={{
                  color: '#6C757D',  
                  paddingRight: 0,
                  paddingLeft: 25
                  }}>
                    KYC VERIFICATION LIST
              </h5> */}
            <div
              style={{
                backgroundColor: "#FFFFFF",
              }}
            >
              <PageHeader
                style={{
                  border: "1px solid rgb(235, 237, 240)",
                }}
                // onBack={() => null}
                title="KYC Pending List"
                subTitle="View, Approve and Reject PENDING KYCs submitted by users from the V2 Unified Mobile App."
              />
            </div>
            <br></br>
            <hr className="m-0 mb-3" />
            <div className="col text-left" style={{ padding: "0px 25px" }}>
              <Radio.Group
                defaultValue="a"
                buttonStyle="solid"
                style={{ marginRight: 1125 }}
              >
                <Link to={"/kyc"}>
                  <Radio.Button value="a">PENDING</Radio.Button>
                </Link>
                <Link to={"/kycApproved"}>
                  <Radio.Button value="b">APPROVED</Radio.Button>
                </Link>
                <Link to={"/kycRejected"}>
                  <Radio.Button value="c">REJECTED</Radio.Button>
                </Link>
              </Radio.Group>
            </div>
            <div class="col text-right" style={{ padding: "0px 25px" }}>
              <CSVLink
                filename={"KYC_Table_PENDING.csv"}
                data={dataSource}
                className="btn btn-warning btn-sm"
              >
                <i
                  class="fas fa-file-export"
                  style={{ fontSize: 12, color: "white" }}
                  aria-hidden="true"
                />
                &nbsp;
                <b style={{ fontSize: 12, color: "white" }}>EXPORT CSV</b>
              </CSVLink>
              &nbsp;
              <button
                className="btn btn-secondary btn-sm"
                onClick={() => {
                  window.location.reload(fetchKYCRecords(1));
                }}
              >
                <i class="fa fa-sync" aria-hidden="true" />
                &nbsp;
                <b style={{ fontSize: 12, color: "white" }}>REFRESH</b>
              </button>
              <br></br>
              &nbsp;
              <Table
                style={{ whiteSpace: "nowrap" }}
                bordered
                loading={loading}
                columns={columnsPending}
                dataSource={dataSource}
                // footer={() => '*Note : The table auto-refreshes every few minutes as to update for new KYC submissions.'}
                rowKey="regcode"
                expandable={{
                  expandedRowRender: (users) => (
                    <div class="container">
                      <hr className="m-0 mb-3" />
                      <div class="row">
                        <div class="col">
                          Further details of user: <b>{users.regcode}</b> -{" "}
                          <b>
                            {users.lname
                              .replaceAll("&#x2F;", "/")
                              .replaceAll("&amp;", "&")
                              .replaceAll("Ã", "Ñ")
                              .replaceAll("Ã±", "ñ")}
                          </b>{" "}
                          ,{" "}
                          <b>
                            {users.fname
                              .replaceAll("&#x2F;", "/")
                              .replaceAll("&amp;", "&")
                              .replaceAll("Ã", "Ñ")
                              .replaceAll("Ã±", "ñ")}
                          </b>{" "}
                          <b>
                            {users.mname
                              .replaceAll("&#x2F;", "/")
                              .replaceAll("Ã±", "ñ")
                              .replaceAll("Ã", "Ñ")}
                          </b>
                        </div>
                      </div>
                      <br></br>
                      <div class="row">
                        <div class="col">
                          Address:{" "}
                          <b>
                            {users.address
                              .replaceAll("&#x2F;", "/")
                              .replaceAll("&amp;", "&")
                              .replaceAll("Ã", "Ñ")
                              .replaceAll("Ã±", "ñ")}
                          </b>
                          ,{" "}
                          <b>
                            {users.addressBrgy
                              .replaceAll("&#x2F;", "/")
                              .replaceAll("&amp;", "&")
                              .replaceAll("Ã", "Ñ")
                              .replaceAll("Ã±", "ñ")}
                          </b>
                          ,{" "}
                          <b>
                            {users.addressCity
                              .replaceAll("&#x2F;", "/")
                              .replaceAll("&amp;", "&")
                              .replaceAll("Ã", "Ñ")
                              .replaceAll("Ã±", "ñ")}
                          </b>
                          ,{" "}
                          <b>
                            {users.addressProvince
                              .replaceAll("&amp;", "&")
                              .replaceAll("Ã", "Ñ")}
                          </b>
                        </div>
                      </div>
                      <br></br>
                      <div class="row">
                        <div class="col">
                          Email Address: <b>{users.emailAdd}</b>
                        </div>
                        <div class="col">
                          Contact Number: <b>{users.mobile}</b>
                        </div>
                      </div>
                      <br></br>
                      <div class="row">
                        <div class="col">
                          Civil Status: <b>{users.civilStatus}</b>
                        </div>
                        <div class="col">
                          Nationality: <b>{users.nationality}</b>
                        </div>
                      </div>
                      <br></br>
                      <div class="row">
                        <div class="col">
                          KYC Level: <b>{users.kycLevel}</b>
                        </div>
                        <div class="col">{/* empty col data */}</div>
                      </div>
                      <br></br>
                      <div class="row">
                        <div class="col">
                          Face Verification: <br></br>
                          <Image
                            height={300}
                            width={200}
                            src={users.selfie_attachment.replaceAll(
                              "&#x2F;",
                              "/"
                            )}
                            placeholder={
                              <Image
                                preview={false}
                                src="https://c.tenor.com/IuABkwIwrUUAAAAC/loading-yellow.gif"
                                width={200}
                              />
                            }
                          />
                        </div>
                        <div class="col">
                          ID Verification: <br></br>
                          {/* <a href={users.id_attachment.replaceAll('&#x2F;', '/').replaceAll(':&#x2F;', '://')}>VIEW CARD IMAGE</a>
                      <br></br> */}
                          <Image
                            height={300}
                            width={200}
                            src={users.id_attachment.replaceAll("&#x2F;", "/")}
                            placeholder={
                              <Image
                                preview={false}
                                src="https://c.tenor.com/IuABkwIwrUUAAAAC/loading-yellow.gif"
                                width={200}
                              />
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ),
                  rowExpandable: (users) => users.regcode !== "Not Expandable",
                }}
                pagination={{
                  // showSizeChanger:false, //removes pagesize changer
                  current: page,
                  pageSize: pageSize,
                  onChange: (page, pageSize) => {
                    setPage(page);
                    setPageSize(pageSize);
                  },
                }}
                scroll={{
                  x: "auto",
                }}
              ></Table>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default KYCTable;
