import { Table, Space, PageHeader, Button, Input, Tag } from "antd";
import Highlighter from 'react-highlight-words';
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import React from 'react';
import Header from "../../elements/header";
import Sidebar from "../../elements/sidebar";
import 'antd/dist/antd.css';
import moment from 'moment';
import Swal from 'sweetalert2';
// import {CSVLink} from "react-csv"

function UserList() {
  
  const [dataSource, setDataSource] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<i class="fa fa-search" aria-hidden="true" />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <i class="fa fa-search" aria-hidden="true"
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const handleClickUnderDevelopment = (event) => {
    let timerInterval
    Swal.fire({
        imageUrl: 'https://cdn1.iconfinder.com/data/icons/ios-11-glyphs/30/maintenance-512.png',
        imageWidth: 115,
        imageHeight: 100,
        imageAlt: 'Under development',
        title: 'Sorry! This page is still under development.',
        allowOutsideClick: false,
        html: 'The page will refresh in a few seconds.',
        timer: 1500,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading()
          const b = Swal.getHtmlContainer().querySelector('b')
          timerInterval = setInterval(() => {
            b.textContent = Swal.getTimerLeft()
          }, 100)
        },
        willClose: () => {
          clearInterval(timerInterval)
        }
      })
      setTimeout((function() {
        window.location.href = "/accessLevels"
      }), 1600);
}

  useEffect(() => {
    fetchUserList(1);
    // eslint-disable-next-line
  }, []);
  const columns = [
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   align: 'center',
    //   fixed: 'left',
    //   width: '12%',
    // },
    {
        title: "User Code",
        dataIndex: "userlevel",
        align: 'center',
        width: '9%',
        fixed: 'left',
        defaultSortOrder: 'ascend',
        ...getColumnSearchProps('userlevel'),
        sorter: (a, b) => a.userlevel - b.userlevel,
    },
      {
        title: "User Level Type",
        dataIndex: "userlevel",
        fixed: 'left',
        align: 'center',
        width: '12%',
        render: (userlevel) => { 
          return (
            <Space size="middle">
              {(userlevel === 1) 
                ? <b>PINOY DEALER</b>
                : (userlevel === 2) 
                ? <b>SUB DEALER</b>
                : (userlevel === 3) 
                ? <b>FREE ACCOUNT</b>
                : (userlevel === 4) 
                ? <b>VISA RETAILER</b> 
                : (userlevel === 5) 
                ? <b>RETAILER</b>
                : (userlevel === 6) 
                ? <b>GLOBAL DEALER</b>
                : (userlevel === 7) 
                ? <b>HUB/RBC/CORPO</b>
                : (userlevel === 13) 
                ? <b>WITH LOANS</b>
                : (userlevel === 14) 
                ? <b>TANGIBLE DEALER (PINOY)</b> 
                : (userlevel === 15) 
                ? <b>GLOBAL DEALER</b> 
                : (userlevel === 16) 
                ? <b>UNIFIED PAY CENTER</b>
                : ""
              }
            </Space>
          )
        }
      },
      {
        title: "Regular Load",
        dataIndex: "regular_load",
        align: 'center',
        width: '12%',
        render: (regular_load) => { return (<p>{(regular_load === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "Special Load",
        dataIndex: "special_load",
        align: 'center',
        width: '12%',
        render: (special_load) => { return (<p>{(special_load === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
        
      },
      {
        title: "Prepaid Load",
        dataIndex: "prepaid_card",
        align: 'center',
        width: '12%',
        render: (prepaid_card) => { return (<p>{(prepaid_card === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
        
      },
      {
        title: "SGD Load",
        dataIndex: "sgd_load",
        align: 'center',
        width: '12%',
        render: (sgd_load) => { return (<p>{(sgd_load === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
        
      },
      {
        title: "AED Fund",
        dataIndex: "aed_fund",
        align: 'center',
        width: '12%',
        render: (aed_fund) => { return (<p>{(aed_fund === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "Etisalat Load",
        dataIndex: "etisalat_load",
        align: 'center',
        width: '12%',
        render: (etisalat_load) => { return (<p>{(etisalat_load === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "UAE Load",
        dataIndex: "uae_load",
        align: 'center',
        width: '12%',
        render: (uae_load) => { return (<p>{(uae_load === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "QAR Load",
        dataIndex: "qar_load",
        align: 'center',
        width: '12%',
        render: (qar_load) => { return (<p>{(qar_load === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "HKD Load",
        dataIndex: "hkd_load",
        align: 'center',
        width: '12%',
        render: (hkd_load) => { return (<p>{(hkd_load === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "Ecash convert to Load Fund",
        dataIndex: "ecash_convert_to_loadfund",
        align: 'center',
        width: '12%',
        render: (ecash_convert_to_loadfund) => { return (<p>{(ecash_convert_to_loadfund === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "Forex Rate",
        dataIndex: "forex_rate",
        align: 'center',
        width: '12%',
        render: (forex_rate) => { return (<p>{(forex_rate === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "Ecash Conversion",
        dataIndex: "ecash_conversion",
        align: 'center',
        width: '12%',
        render: (ecash_conversion) => { return (<p>{(ecash_conversion === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "Ecash to Ecash",
        dataIndex: "ecash_to_ecash",
        align: 'center',
        width: '12%',
        render: (ecash_to_ecash) => { return (<p>{(ecash_to_ecash === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "Ecash Padala Send",
        dataIndex: "ecash_padala_send",
        align: 'center',
        width: '12%',
        render: (ecash_padala_send) => { return (<p>{(ecash_padala_send === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "Smartmoney Send",
        dataIndex: "smartmoney_send",
        align: 'center',
        width: '12%',
        render: (smartmoney_send) => { return (<p>{(smartmoney_send === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "Credit to Bank Send",
        dataIndex: "credit_to_bank_send",
        align: 'center',
        width: '12%',
        render: (credit_to_bank_send) => { return (<p>{(credit_to_bank_send === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "Ecash to Ecash Card Send",
        dataIndex: "ecash_to_cash_card_send",
        align: 'center',
        width: '12%',
        render: (ecash_to_cash_card_send) => { return (<p>{(ecash_to_cash_card_send === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "Ecash Cardless Padala Send",
        dataIndex: "ecash_cardless_padala_send",
        align: 'center',
        width: '12%',
        render: (ecash_cardless_padala_send) => { return (<p>{(ecash_cardless_padala_send === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "Ecash Cebuana Send",
        dataIndex: "ecash_cebuana_send",
        align: 'center',
        width: '12%',
        render: (ecash_cebuana_send) => { return (<p>{(ecash_cebuana_send === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "Western Union Send",
        dataIndex: "western_union_send",
        align: 'center',
        width: '12%',
        render: (western_union_send) => { return (<p>{(western_union_send === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "Gcash Send",
        dataIndex: "gcash_send",
        align: 'center',
        width: '12%',
        render: (gcash_send) => { return (<p>{(gcash_send === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "Paymaya Send",
        dataIndex: "paymaya_send",
        align: 'center',
        width: '12%',
        render: (paymaya_send) => { return (<p>{(paymaya_send === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "Gocab Send",
        dataIndex: "gocab_send",
        align: 'center',
        width: '12%',
        render: (gocab_send) => { return (<p>{(gocab_send === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "Smartmoney Payout",
        dataIndex: "smartmoney_payout",
        align: 'center',
        width: '12%',
        render: (smartmoney_payout) => { return (<p>{(smartmoney_payout === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "Ecash Padala Payout",
        dataIndex: "ecash_padala_payout",
        align: 'center',
        width: '12%',
        render: (ecash_padala_payout) => { return (<p>{(ecash_padala_payout === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "iRemit Payout",
        dataIndex: "iremit_payout",
        align: 'center',
        width: '12%',
        render: (iremit_payout) => { return (<p>{(iremit_payout === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "Transfast Payout",
        dataIndex: "transfast_payout",
        align: 'center',
        width: '12%',
        render: (transfast_payout) => { return (<p>{(transfast_payout === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "Moneygram Payout",
        dataIndex: "moneygram_payout",
        align: 'center',
        width: '12%',
        render: (moneygram_payout) => { return (<p>{(moneygram_payout === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "Cebuana Domestic Payout",
        dataIndex: "cebuana_domestic_payout",
        align: 'center',
        width: '12%',
        render: (cebuana_domestic_payout) => { return (<p>{(cebuana_domestic_payout === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "Western Union Payout",
        dataIndex: "western_union_payout",
        align: 'center',
        width: '12%',
        render: (western_union_payout) => { return (<p>{(western_union_payout === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "Bills Payments",
        dataIndex: "bills_payment",
        align: 'center',
        width: '12%',
        render: (bills_payment) => { return (<p>{(bills_payment === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "International Flight",
        dataIndex: "international_flight",
        align: 'center',
        width: '12%',
        render: (international_flight) => { return (<p>{(international_flight === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "Pai Insurance",
        dataIndex: "pai_insurance",
        align: 'center',
        width: '12%',
        render: (pai_insurance) => { return (<p>{(pai_insurance === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "CTPL Insurance",
        dataIndex: "ctpl_insurance",
        align: 'center',
        width: '12%',
        render: (ctpl_insurance) => { return (<p>{(ctpl_insurance === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "Hotel Booking",
        dataIndex: "hotel_booking",
        align: 'center',
        width: '12%',
        render: (hotel_booking) => { return (<p>{(hotel_booking === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "Buy Codes",
        dataIndex: "buy_codes",
        align: 'center',
        width: '12%',
        render: (buy_codes) => { return (<p>{(buy_codes === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "Upgrade Account",
        dataIndex: "upgrade_account",
        align: 'center',
        width: '12%',
        render: (upgrade_account) => { return (<p>{(upgrade_account === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "MLM Shop",
        dataIndex: "mlm_shop",
        align: 'center',
        width: '12%',
        render: (mlm_shop) => { return (<p>{(mlm_shop === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "Fund Request",
        dataIndex: "fund_request",
        align: 'center',
        width: '12%',
        render: (fund_request) => { return (<p>{(fund_request === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "Universal Load Wallet",
        dataIndex: "universal_load_wallet",
        align: 'center',
        width: '12%',
        render: (universal_load_wallet) => { return (<p>{(universal_load_wallet === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "AED Fund",
        dataIndex: "aed_fund",
        align: 'center',
        width: '12%',
        render: (aed_fund) => { return (<p>{(aed_fund === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "QATAR Fund",
        dataIndex: "qatar_fund",
        align: 'center',
        width: '12%',
        render: (qatar_fund) => { return (<p>{(qatar_fund === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "HKD Fund",
        dataIndex: "hkd_fund",
        align: 'center',
        width: '12%',
        render: (hkd_fund) => { return (<p>{(hkd_fund === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "SGD Fund",
        dataIndex: "sgd_fund",
        align: 'center',
        width: '12%',
        render: (sgd_fund) => { return (<p>{(sgd_fund === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "MYR Fund",
        dataIndex: "myr_fund",
        align: 'center',
        width: '12%',
        render: (myr_fund) => { return (<p>{(myr_fund === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "Purchase Unified E-Wallet",
        dataIndex: "purchase_unified_ewallet",
        align: 'center',
        width: '12%',
        render: (purchase_unified_ewallet) => { return (<p>{(purchase_unified_ewallet === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "Ecash Reload through Dragon Pay",
        dataIndex: "ecash_reload_thru_dragonpay",
        align: 'center',
        width: '12%',
        render: (ecash_reload_thru_dragonpay) => { return (<p>{(ecash_reload_thru_dragonpay === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "Gcash Cashin",
        dataIndex: "gcash_cashin",
        align: 'center',
        width: '12%',
        render: (gcash_cashin) => { return (<p>{(gcash_cashin === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "Paymaya Cashin",
        dataIndex: "paymaya_cashin",
        align: 'center',
        width: '12%',
        render: (paymaya_cashin) => { return (<p>{(paymaya_cashin === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "GrabPay Cashin",
        dataIndex: "grabpay_cashin",
        align: 'center',
        width: '12%',
        render: (grabpay_cashin) => { return (<p>{(grabpay_cashin === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "GoCab Cashin",
        dataIndex: "gocab_cashin",
        align: 'center',
        width: '12%',
        render: (gocab_cashin) => { return (<p>{(gocab_cashin === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "GoCab Cashout",
        dataIndex: "gocab_cashout",
        align: 'center',
        width: '12%',
        render: (gocab_cashout) => { return (<p>{(gocab_cashout === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "Emergency Fund",
        dataIndex: "emergency_fund",
        align: 'center',
        width: '12%',
        render: (emergency_fund) => { return (<p>{(emergency_fund === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "Shipping",
        dataIndex: "shipping",
        align: 'center',
        width: '12%',
        render: (shipping) => { return (<p>{(shipping === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "Income",
        dataIndex: "income",
        align: 'center',
        width: '12%',
        render: (income) => { return (<p>{(income === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "Markup",
        dataIndex: "markup",
        align: 'center',
        width: '12%',
        render: (markup) => { return (<p>{(markup === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "Unilevel",
        dataIndex: "unilevel",
        align: 'center',
        width: '12%',
        render: (unilevel) => { return (<p>{(unilevel === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "Unified Staycation",
        dataIndex: "unified_staycation",
        align: 'center',
        width: '12%',
        render: (unified_staycation) => { return (<p>{(unified_staycation === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "Unified Healthcare",
        dataIndex: "unified_healthcare",
        align: 'center',
        width: '12%',
        render: (unified_healthcare) => { return (<p>{(unified_healthcare === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "Bus",
        dataIndex: "bus",
        align: 'center',
        width: '12%',
        render: (bus) => { return (<p>{(bus === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "Genealogy",
        dataIndex: "genealogy",
        align: 'center',
        width: '12%',
        render: (genealogy) => { return (<p>{(genealogy === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "Network Income",
        dataIndex: "network_income",
        align: 'center',
        width: '12%',
        render: (network_income) => { return (<p>{(network_income === true) 
            ? <Tag color="#53C63F">HAS ACCESS</Tag>
            : <Tag color="#C70039">NO ACCESS</Tag>}</p>)}
      },
      {
        title: "Disabled",
        dataIndex: "disabled",
        align: 'center',
        width: '8%',
        render: (disabled) => { return (<p>{(disabled === true) 
            ? <Tag color="#C70039">YES</Tag>
            : <Tag color="#53C63F">NO</Tag>}</p>)}
      },
      {
        title: "Created At",
        dataIndex: "createdAt",
        align: 'center',
        width: '12%',
        //sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
        render: (createdAt) => { return (<p>{moment(createdAt).format("YYYY-MM-DD HH:mm")}</p>)}
      },
      {
        title: "Updated At",
        dataIndex: "updatedAt",
        align: 'center',
        defaultSortOrder: 'descend',
        width: '12%',
        //sorter: (a, b) => moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
        render: (updatedAt) => { return (<p>{moment(updatedAt).format("YYYY-MM-DD HH:mm")}</p>)}
      },
      {
        title: <center>Actions</center>,
        key: 'action',
        fixed: 'right',
        width: '8%',
    
        render: (_, users) => (
          <Space size="middle">
                <div style={{    justifyContent: 'center', }}>
                <Button type="text" value={users.regcode} 
                        className="btn btn-link text-primary text-decoration-none p-1 mx-2 font-sm" 
                        style={{ color: '#FFB200' }} 
                          onClick={() => {
                            handleClickUnderDevelopment(users.regcode);
                          }}>
                        <i className="fas fa-edit"  style={{ color: '#FFB200' }} />&nbsp;<b  style={{ color: '#FFB200', fontSize: 12 }}>{"EDIT ACCESS"}</b>
                </Button>
                </div>      
          </Space>
        ),
      },
      
      
  ];

  const fetchUserList = (page) => {
    setLoading(true);
    axios
      .get('https://v2.unified.ph/v2-admin-accesslevel/index', {
        headers: {
            'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InJlbmVib3kiLCJyZWdjb2RlIjoidGVzdCIsImlhdCI6MTY1MzM3ODI2M30.nk3ltTC06VmmDuqYAwNpiP-URQGNsg_IwvoAnQZewbQ`
        },})
      .then((res) => {
        setDataSource(res?.data.data);
        setTotalPages(setDataSource.totalPages);
        setLoading(false);
        // console.log("USER_LIST", res?.data.data)
      });
  };

  return (
       <div style={{
        backgroundColor: '#EEEEEE',
        }}>
          <Header/>
          <div id="wrapper">
              <Sidebar/>
              <div id="content-wrapper">
              <div className="container-fluid">
                  <div><br></br></div>
              {/* <h5 style={{
                  color: '#6C757D',  
                  paddingRight: 0,
                  paddingLeft: 25
                  }}>
                    KYC VERIFICATION LIST
              </h5> */}
              <div style={{
                   backgroundColor: '#FFFFFF',
                   }}>
                <PageHeader
                    style={{
                    border: '1px solid rgb(235, 237, 240)',
                    }}
                    // onBack={() => null}
                    title="User Access Levels"
                    subTitle=""
                />
              </div>
         <br></br>
        <hr className="m-0 mb-3" />
            <div class="col text-right" style={{ padding:"0px 25px"}}>
            {/* <CSVLink
              filename={"GenReports_Table.csv"}
              data={dataSource}
              className="btn btn-warning btn-sm"
            >
            <i class="fas fa-file-export" style={{ fontSize: 12,  color: 'white'}} aria-hidden="true" />&nbsp;
            <b style={{ fontSize: 12,  color: 'white'}}>EXPORT CSV</b>
            </CSVLink> */}
        </div>
        
          <div className="card-body">
          <Table
            bordered
            loading={loading}
            columns={columns}
            dataSource={dataSource}
            // footer={() => '*Note : The table auto-refreshes every few minutes as to update for new KYC submissions.'}
            rowKey="regcode"
        pagination={{
          // showSizeChanger:false, //removes pagesize changer
          // pageSize: 10,
          total: totalPages,
          onChange: (page) => {
            fetchUserList(page);
          },
        }}
        scroll={{
          x: 14000,
        }}
    >
          </Table>
          </div>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  }}>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}
export default UserList;